import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { utc_time_zone } from "./constants";

export interface StoreState {
  all_stores_data: any;
  all_stores: any;
  single_store: any;
  create_store: any;
  update_store: any;

  all_store_groups_data: any;
  all_store_groups: any;
  single_store_group: any;
  create_store_group: any;
  update_store_group: any;

  activity: boolean;
}

interface APIParams {
  end_point: string;
  body: any;
}

const initialState: StoreState = {
  all_stores_data: [],
  all_stores: [],
  single_store: {},
  create_store: {},
  update_store: {},

  all_store_groups_data: [],
  all_store_groups: [],
  single_store_group: {},
  create_store_group: {},
  update_store_group: {},

  activity: false,
};

export const all_stores = createAsyncThunk(
  "stores/",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "stores");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_store = createAsyncThunk(
  "stores/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "stores");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_store = createAsyncThunk(
  "stores/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "stores"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_store = createAsyncThunk(
  "stores/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "stores"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const all_store_groups = createAsyncThunk(
  "store-groups/",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "store-groups"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_store_group = createAsyncThunk(
  "store-groups/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "store-groups"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_store_group = createAsyncThunk(
  "store-groups/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "store-groups"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_store_group = createAsyncThunk(
  "store-groups/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "store-groups"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const stores_slicer = createSlice({
  name: "stores",
  initialState,
  reducers: {
    handle_clear_form: (state: StoreState, action: any) => {
      state.create_store = {};
      state.single_store = {};
      state.update_store = {};
      state.create_store_group = {};
      state.single_store_group = {};
      state.update_store_group = {};
      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(all_stores.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_stores.fulfilled, (state, { payload }) => {
        try {
          let stores = [] as any;

          payload?.data?.sort(function (a: any, b: any) {
            var keyA = new Date(a.store_code),
              keyB = new Date(b.store_code);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            stores.push([
              item?.store_code || "Not Available",
              item?.name || "Not Available",
              {
                value: item?.is_actived,
                name: item?.is_actived ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_actived ? "#6EC531" : "#D2112B",
              },
              item?.ip_address || "Not Available",
            ]);
          }

          state.all_stores_data = payload?.data;
          state.all_stores = stores;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(single_store.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_store.fulfilled, (state, { payload }) => {
        try {
          state.single_store = payload?.data;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(create_store.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_store.fulfilled, (state, { payload }) => {
        try {
          state.create_store = payload?.data;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(update_store.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_store.fulfilled, (state, { payload }) => {
        try {
          state.update_store = payload?.data;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(all_store_groups.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_store_groups.fulfilled, (state, { payload }) => {
        try {
          let stores = [] as any;

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            stores.push([
              { name: item?.id ?? "Not Available", hidden: true },
              item?.store_group_name ?? "Not Available",
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#D2112B",
              },
              item?.created_at
                ? moment(item?.created_at)
                    .utcOffset(utc_time_zone)
                    .format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              item?.updated_at
                ? moment(item?.updated_at)
                    .utcOffset(utc_time_zone)
                    .format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
            ]);
          }

          state.all_store_groups_data = payload?.data;
          state.all_store_groups = stores;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(single_store_group.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_store_group.fulfilled, (state, { payload }) => {
        try {
          state.single_store_group = payload?.data;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(create_store_group.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_store_group.fulfilled, (state, { payload }) => {
        try {
          state.create_store_group = payload;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(update_store_group.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_store_group.fulfilled, (state, { payload }) => {
        try {
          state.update_store_group = payload;
          state.activity = false;
        } catch (error) {}
      });
  },
});

export const { handle_clear_form } = stores_slicer.actions;

export default stores_slicer.reducer;
