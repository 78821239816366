/* REACT ROUTER */
import { Link } from "react-router-dom";

/* MATERIAL UI */
import { ButtonBase } from "@mui/material";

/* PROJECT IMPORTS */
import config from "config";

/* IMAGES */
import logo from "../../../assets/images/logo/dominos-logo.png"

const LogoSection = () => {
  return (
    <ButtonBase disableRipple disabled component={Link} to={config.defaultPath}>
      <img src={logo} width={180} alt={"Dominos Logo"} />
    </ButtonBase>
  );
};

export default LogoSection;
