import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { utc_time_zone } from "./constants";

export interface WarehouseState {
  all_warehouse_data: any;
  all_warehouse: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
  body: any;
}

const initialState: WarehouseState = {
  all_warehouse_data: [],
  all_warehouse: [],
  activity: false,
};

export const all_warehouses = createAsyncThunk(
  "/warehouses/",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "warehouses");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const warehouses_slicer = createSlice({
  name: "warehouses",
  initialState,
  reducers: {
    handle_clear_form: (state: WarehouseState, action: any) => {
      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(all_warehouses.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_warehouses.fulfilled, (state, { payload }) => {
        try {
          let warehouses = [] as any;

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            warehouses.push([
              { name: item?.id ?? "Not Available", hidden: true },
              item?.warehouse_code ?? "Not Available",
              item?.warehouse_name ?? "Not Available",
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#D2112B",
                alignment: "center",
              },
              {
                name: moment(item?.created_at)
                  .utcOffset(utc_time_zone)
                  .format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name:
                  item?.updated_at !== item?.created_at
                    ? moment(item?.updated_at)
                        .utcOffset(utc_time_zone)
                        .format("DD.MMM.YYYY HH:mm:ss")
                    : "Not Available",
                alignment: "center",
              },
            ]);
          }

          state.all_warehouse_data = payload?.data;
          state.all_warehouse = warehouses;
          state.activity = false;
        } catch (error) {}
      });
  },
});

export const { handle_clear_form } = warehouses_slicer.actions;

export default warehouses_slicer.reducer;
