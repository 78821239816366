import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { ListItemButton, ListItemIcon, useMediaQuery } from "@mui/material";
import { RootState } from "store";
import { handleClose, handleMenuClick } from "store/drawerReducer";
import { ListAlt } from "@mui/icons-material";
import {
  AttendenceSVG,
  ChannelSalesSVG,
  ConsolidateCostOfGoodsSoldSVG,
  ConsolidatePulseInventorySVG,
  ConsolidateSalesSVG,
  DashboardSVG,
  DepartmentsSVG,
  DesignationsSVG,
  EmployeesSVG,
  HolidaysSVG,
  InventoryRegisterSVG,
  ItemApprovalLevelSVG,
  ItemGroupSVG,
  ItemsSVG,
  MaterialRequestSVG,
  PaymentRequestSVG,
  PaymentSummarySVG,
  PulseCategorySVG,
  RolesSVG,
  SalesRegisterSVG,
  ShiftsSVG,
  StoresSVG,
  TimesheetSVG,
  UnitOfMeasurementCategorySVG,
  UnitOfMeasurementSVG,
  UsersSVG,
  WeeklyScheduleSVG,
  WorkflowActionsSVG,
  WorkflowSVG,
  WorkflowStatesSVG,
} from "assets/svgs";

const NavItem = ({ item, level }: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state: RootState) => state.drawer);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref as any} {...props} to={item.url} target={itemTarget} />
    )),
    href: "",
    target: null,
  };

  if (item?.external) {
    listItemProps = {
      component: "a" as any,
      href: item.url as any,
      target: itemTarget as any,
    };
  }

  const itemHandler = (id: any) => {
    dispatch(handleMenuClick(id));

    if (matchesSM) dispatch(handleClose());
  };

  const getDrawerIcons = (item: any) => {
    if (item.id === "dashboard") {
      return <DashboardSVG width={20} height={20} />;
    } else if (item.id === "sales-register") {
      return <SalesRegisterSVG width={20} height={20} />;
    } else if (item.id === "inventory-register") {
      return <InventoryRegisterSVG width={20} height={20} />;
    } else if (item.id === "item-group") {
      return <ItemGroupSVG width={20} height={20} />;
    } else if (item.id === "items") {
      return <ItemsSVG width={20} height={20} />;
    } else if (item.id === "unit-of-measurement") {
      return <UnitOfMeasurementSVG width={20} height={20} />;
    } else if (item.id === "uom-category") {
      return <UnitOfMeasurementCategorySVG width={20} height={20} />;
    } else if (item.id === "pulse-category") {
      return <PulseCategorySVG width={20} height={20} />;
    } else if (item.id === "item-approval-level") {
      return <ItemApprovalLevelSVG width={20} height={20} />;
    } else if (item.id === "payment-summary") {
      return <PaymentSummarySVG width={20} height={20} />;
    } else if (item.id === "payment-request") {
      return <PaymentRequestSVG width={20} height={20} />;
    } else if (item.id === "material-request") {
      return <MaterialRequestSVG width={20} height={20} />;
    } else if (item.id === "departments") {
      return <DepartmentsSVG width={20} height={20} />;
    } else if (item.id === "designations") {
      return <DesignationsSVG width={20} height={20} />;
    } else if (item.id === "employee") {
      return <EmployeesSVG width={20} height={20} />;
    } else if (item.id === "shifts") {
      return <ShiftsSVG width={20} height={20} />;
    } else if (item.id === "holidays") {
      return <HolidaysSVG width={20} height={20} />;
    } else if (item.id === "weekly-schedule") {
      return <WeeklyScheduleSVG width={20} height={20} />;
    } else if (item.id === "attendence") {
      return <AttendenceSVG width={20} height={20} />;
    } else if (item.id === "timesheet") {
      return <TimesheetSVG width={20} height={20} />;
    } else if (item.id === "consolidate-sales") {
      return <ConsolidateSalesSVG />;
    } else if (item.id === "channel-sales") {
      return <ChannelSalesSVG />;
    } else if (item.id === "consolidate-cogs") {
      return <ConsolidateCostOfGoodsSoldSVG />;
    } else if (item.id === "consolidate-pulse-inventory") {
      return <ConsolidatePulseInventorySVG />;
    } else if (item.id === "users") {
      return <UsersSVG />;
    } else if (item.id === "roles") {
      return <RolesSVG />;
    } else if (item.id === "stores") {
      return <StoresSVG />;
    } else if (item.id === "workflow-register") {
      return <WorkflowSVG />;
    } else if (item.id === "workflow-states") {
      return <WorkflowStatesSVG />;
    } else if (item.id === "workflow-actions") {
      return <WorkflowActionsSVG />;
    } else {
      return <ListAlt />;
    }
  };

  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(handleMenuClick(item.id));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        alignItems: "flex-start",
        textTransform: "uppercase",
        py:
          item.id === "dashboard" || item.id === "settings"
            ? `${level * 5}px`
            : `${level * 3}px`,
        pl:
          item.id === "dashboard" || item.id === "settings"
            ? 1
            : item.id === "workflow-states" || item.id === "workflow-actions"
            ? `${level * 20}px`
            : `${level * 10}px`,
      }}
      selected={
        customization.isOpen.findIndex((id: any) => id === item.id) > -1
      }
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
        {getDrawerIcons(item)}
      </ListItemIcon>
      <p style={{ marginBottom: 0, flex: 1, paddingLeft: 10 }}>{item.title}</p>
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
