import { Login, ForgotPassword, EmployeeTimeClock } from "./PageAsync";
import PropTypes from "prop-types";

export function AuthenticationRoutes({ classes }: any) {
  const AuthRoutes = {
    path: "/",
    children: [
      {
        path: "/",
        element: <Login classes={classes} />,
      },
      {
        path: "/login",
        element: <Login classes={classes} />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword classes={classes} />,
      },
      {
        path: "/clock",
        element: <EmployeeTimeClock classes={classes} />,
      },
      {
        path: "/*",
        element: <Login classes={classes} />,
      },
    ],
  };

  return AuthRoutes;
}

AuthenticationRoutes.propTypes = {
  classes: PropTypes.object,
};
