import { configureStore } from "@reduxjs/toolkit";
import BreadcrumbSlice from "./breadcrumbReducer";
import drawerReducer from "./drawerReducer";
import storeReducer from "./storeReducer";
import salesReducer from "./salesReducer";
import usersReducer from "./usersReducer";
import rolesReducer from "./rolesReducer";
import workflowsReducer from "./workflowsReducer";
import departmentsReducer from "./departmentsReducer";
import designationsReducer from "./designationsReducer";
import employeeReducer from "./employeeReducer";
import modulesReducer from "./modulesReducer";
import items_group_slicer from "./itemReducer";
import material_request_slicer from "./materialRequestReducer";
import loggerReducer from "./loggerReducer";
import warehousesReducer from "./warehousesReducer";
import timesheetReducer from "./timesheetReducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    store: storeReducer,
    warehouse: warehousesReducer,
    sales: salesReducer,
    users: usersReducer,
    roles: rolesReducer,
    departments: departmentsReducer,
    designations: designationsReducer,
    employees: employeeReducer,
    modules: modulesReducer,
    workflows: workflowsReducer,
    breadcrumb: BreadcrumbSlice,
    items: items_group_slicer,
    material: material_request_slicer,
    logger: loggerReducer,
    timesheet: timesheetReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
