import { lazy } from "react";
import Loadable from "ui-components/Loadable";

export const Login = Loadable(lazy(() => import("views/login_panel")));

export const ForgotPassword = Loadable(
  lazy(() => import("views/forgot_password"))
);

export const EmployeeTimeClock = Loadable(
  lazy(() => import("views/employee_time_clock"))
);

export const UserProfile = Loadable(lazy(() => import("views/user_profile")));

export const Dashboard = Loadable(lazy(() => import("views/dashboard")));

export const SalesRegister = Loadable(
  lazy(() => import("views/sales_register"))
);

export const SaleDetails = Loadable(lazy(() => import("views/sale_details")));

export const SalesDashboard = Loadable(
  lazy(() => import("views/sales_dashboard"))
);

export const CostOfGoodsSoldRegister = Loadable(
  lazy(() => import("views/cost_of_goods_sold_register"))
);

export const InventoryDetails = Loadable(
  lazy(() => import("views/inventory_details"))
);

export const PaymentSummaryReport = Loadable(
  lazy(() => import("views/payment_summary_report"))
);

export const PaymentRequestForm = Loadable(
  lazy(() => import("views/payment_request_form"))
);

export const MaterialRequestRegister = Loadable(
  lazy(() => import("views/material_request"))
);

export const MaterialRequestDetail = Loadable(
  lazy(() => import("views/material_request_details"))
);

export const ConsolidateSalesReport = Loadable(
  lazy(() => import("views/consolidate_sales_report"))
);

export const ChannelSalesReport = Loadable(
  lazy(() => import("views/channel_sales_report"))
);

export const PulseConsolidateInventoryReport = Loadable(
  lazy(() => import("views/consolidate_pulse_inventory_report"))
);

export const ConsolidateCostOfGoodsSoldReport = Loadable(
  lazy(() => import("views/consolidate_cost_of_goods_sold_report"))
);

export const UsersRegister = Loadable(
  lazy(() => import("views/users_register"))
);

export const UserDetails = Loadable(lazy(() => import("views/user_details")));

export const RolesRegister = Loadable(
  lazy(() => import("views/roles_register"))
);

export const RoleDetails = Loadable(lazy(() => import("views/role_details")));

export const StoresRegister = Loadable(
  lazy(() => import("views/stores_register"))
);

export const StoreDetails = Loadable(lazy(() => import("views/store_details")));

export const StoreGroupsRegister = Loadable(
  lazy(() => import("views/store_groups_register"))
);

export const StoreGroupDetails = Loadable(
  lazy(() => import("views/store_group_details"))
);

export const WorkflowsRegister = Loadable(
  lazy(() => import("views/workflows_register"))
);

export const WorkflowDetails = Loadable(
  lazy(() => import("views/workflow_details"))
);

export const WorkflowStates = Loadable(
  lazy(() => import("views/workflow_states_register"))
);

export const WorkflowStateDetails = Loadable(
  lazy(() => import("views/workflow_state_details"))
);

export const WorkflowActions = Loadable(
  lazy(() => import("views/workflow_actions_register"))
);

export const WorkflowActionDetails = Loadable(
  lazy(() => import("views/workflow_action_details"))
);

export const AllDepartments = Loadable(
  lazy(() => import("views/departments_register"))
);

export const AllEmployees = Loadable(
  lazy(() => import("views/employee_register"))
);

export const EmployeeDetail = Loadable(
  lazy(() => import("views/employee_details"))
);

export const DepartmentDetail = Loadable(
  lazy(() => import("views/department_details"))
);

export const AllDesginations = Loadable(
  lazy(() => import("views/designations_register"))
);

export const AllShifts = Loadable(lazy(() => import("views/employee_shifts")));

export const AllHolidays = Loadable(
  lazy(() => import("views/employee_holidays"))
);

export const ShiftDetails = Loadable(
  lazy(() => import("views/employee_shifts_details"))
);

export const HolidayDetails = Loadable(
  lazy(() => import("views/employee_holidays_details"))
);

export const EmployeeRoster = Loadable(
  lazy(() => import("views/employee_roster"))
);

export const RosterRegister = Loadable(
  lazy(() => import("views/employee_roster_register"))
);

export const DesignationDetail = Loadable(
  lazy(() => import("views/designation_details"))
);

export const ItemGroup = Loadable(lazy(() => import("views/item_group")));

export const ItemGroupDetails = Loadable(
  lazy(() => import("views/item_group_details"))
);

export const ItemUnitOfMeasurement = Loadable(
  lazy(() => import("views/item_unit_of_measurement"))
);

export const UnitOfMeasurementDetails = Loadable(
  lazy(() => import("views/item_uom_details"))
);

export const UnitOfMeasurementCategory = Loadable(
  lazy(() => import("views/item_uom_category"))
);

export const ItemUomCategoryDeatils = Loadable(
  lazy(() => import("views/item_uom_category_details"))
);

export const UnitOfMeasurementFactor = Loadable(
  lazy(() => import("views/item_uom_factors"))
);

export const UnitOfMeasurementFactorDetails = Loadable(
  lazy(() => import("views/item_uom_factor_details"))
);

export const PulseCategory = Loadable(
  lazy(() => import("views/pulse_category"))
);

export const PulseCategoryDetails = Loadable(
  lazy(() => import("views/pulse_category_details"))
);

export const ItemGroupLevel = Loadable(
  lazy(() => import("views/item_group_level"))
);

export const ItemGroupLevelDetails = Loadable(
  lazy(() => import("views/item_group_level_details"))
);

export const Items = Loadable(lazy(() => import("views/item")));

export const ItemDetails = Loadable(lazy(() => import("views/item_details")));

export const EmployeeTimesheetRegister = Loadable(
  lazy(() => import("views/employee_timesheet_register"))
);

export const EmployeeTimesheetDetail = Loadable(
  lazy(() => import("views/employee_timesheet_details"))
);

export const EmployeeMonthlyTimesheet = Loadable(
  lazy(() => import("views/employee_monthly_timesheet"))
);

export const RequestVacation = Loadable(
  lazy(() => import("views/request_vacation"))
);

export const VacationsRegister = Loadable(
  lazy(() => import("views/vacations_register"))
);

export const VacationDetails = Loadable(
  lazy(() => import("views/vacation_details"))
);

export const LeavesRegister = Loadable(
  lazy(() => import("views/leaves_register"))
);

export const PayrollRegister = Loadable(
  lazy(() => import("views/payroll_register"))
);

export const VacationsReport = Loadable(
  lazy(() => import("views/vacations_report"))
);

export const ErrorPage = Loadable(lazy(() => import("views/error_401_page")));

export const NotFound = Loadable(lazy(() => import("views/error_404_page")));
