import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import apiServices from "services/RequestHandler";

export interface ModuleState {
  all_modules_data: any;
  all_modules: any;
  single_module: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: ModuleState = {
  all_modules_data: [],
  all_modules: [],
  single_module: {},
  activity: true,
};

export const all_modules = createAsyncThunk(
  "/modules",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "modules");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_module = createAsyncThunk(
  "/modules/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "modules");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const modules_slicer = createSlice({
  name: "modules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(all_modules.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_modules.fulfilled, (state, { payload }) => {
        try {
          let modules = [] as any;

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            modules.push([
              {name: item?._id || "Not Available", hidden: true},
              (_.startCase(item?.module_name) || "Not Available"),
              (_.startCase(item?.module_description) || "Not Available"),
              moment(item?.created_at).format("DD.MMM.YYYY"),
              moment(item?.updated_at).format("DD.MMM.YYYY"),
              Date.now()
            ]);
          }

          state.all_modules_data = [...payload?.data];
          state.all_modules = modules;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(single_module.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_module.fulfilled, (state, { payload }) => {
        try {
          state.single_module = payload.data;
          state.activity = false;
        } catch (error) {}
      });
  },
});

export default modules_slicer.reducer;