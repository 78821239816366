const menuItems = {
  items: [
    {
      id: "_dashboard",
      title: "Dashboard",
      type: "group",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/dashboard",
          breadcrumbs: true,
          disabled: true,
        },
      ],
    },

    {
      id: "_sales",
      title: "Sales",
      type: "group",
      children: [
        {
          id: "sales",
          title: "Sales",
          type: "item",
          url: "/sales",
          breadcrumbs: true,
        },
      ],
    },

    {
      id: "_purchase",
      title: "Purchase",
      type: "group",
      children: [
        {
          id: "purchase",
          title: "Purchase",
          type: "collapse",
          children: [
            {
              id: "paymentRequest",
              title: "Payment Request",
              type: "item",
              url: "/purchase/payment-request",
              breadcrumbs: true,
            },
          ],
        },
      ],
    },

    {
      id: "_inventory",
      title: "Inventory",
      type: "group",
      children: [
        {
          id: "inventory",
          title: "Inventory",
          type: "item",
          url: "/inventory",
          breadcrumbs: true,
        },
      ],
    },

    {
      id: "_finance",
      title: "Finance",
      type: "group",
      children: [
        {
          id: "finance",
          title: "Finance",
          type: "collapse",
          children: [
            {
              id: "paymentSummary",
              title: "Payment Summary",
              type: "item",
              url: "/finance/payment-summary",
              breadcrumbs: true,
            },
            {
              id: "accountsPayable",
              title: "Accounts Payable",
              type: "item",
              url: "#",
              breadcrumbs: true,
              disabled: true,
            },
            {
              id: "accountsRecievable",
              title: "Accounts Recievable",
              type: "item",
              url: "#",
              breadcrumbs: true,
              disabled: true,
            },
            {
              id: "budgetForecast",
              title: "Budget & Forecast",
              type: "item",
              url: "#",
              breadcrumbs: true,
              disabled: true,
            },
          ],
        },
      ],
    },

    {
      id: "_finances",
      title: "Finances",
      type: "group",
      children: [
        {
          id: "finance",
          title: "Finance",
          type: "collapse",
          children: [
            {
              id: "paymentSummary",
              title: "Payment Summary",
              type: "item",
              url: "/finance/payment-summary",
              breadcrumbs: true,
            },
          ],
        },
      ],
    },

    {
      id: "_humanResource",
      title: "Human Resources",
      type: "group",
      children: [
        {
          id: "humanResource",
          title: "Human Resources",
          type: "item",
          url: "#",
          breadcrumbs: true,
          disabled: true,
        },
      ],
    },

    {
      id: "_payroll",
      title: "Payroll",
      type: "group",
      children: [
        {
          id: "payroll",
          title: "Payroll",
          type: "item",
          url: "#",
          breadcrumbs: true,
          disabled: true,
        },
      ],
    },

    {
      id: "_reporting-analytic",
      title: "Reporting Analytic",
      type: "group",
      children: [
        {
          id: "reporting-analytics",
          title: "Reporting Analytics",
          type: "collapse",
          children: [
            {
              id: "consolidateSales",
              title: "Consolidate Sales",
              type: "item",
              url: "/reporting-analytics/consolidate-sales",
              breadcrumbs: true,
            },
          ],
        },
      ],
    },

    {
      id: "_reporting-analytics",
      title: "Reporting Analytics",
      type: "group",
      children: [
        {
          id: "reporting-analytics",
          title: "Reporting Analytics",
          type: "collapse",
          children: [
            {
              id: "consolidateSales",
              title: "Consolidate Sales",
              type: "item",
              url: "/reporting-analytics/consolidate-sales",
              breadcrumbs: true,
            },
            {
              id: "channelSales",
              title: "Channel Sales",
              type: "item",
              url: "/reporting-analytics/channel-sales",
              breadcrumbs: true,
            },
            {
              id: "consolidateCOGS",
              title: "Consolidate Inventory",
              type: "item",
              url: "/reporting-analytics/consolidate-cogs",
              breadcrumbs: true,
            },
          ],
        },
      ],
    },

    {
      id: "_settings",
      title: "Settings",
      type: "group",
      children: [
        {
          id: "settings",
          title: "Settings",
          type: "collapse",
          children: [
            {
              id: "users",
              title: "Users",
              type: "item",
              url: "/users",
              breadcrumbs: true,
            },
            {
              id: "roles",
              title: "Roles",
              type: "item",
              url: "/roles",
              breadcrumbs: true,
            },
            {
              id: "stores",
              title: "Stores",
              type: "item",
              url: "/stores",
              breadcrumbs: true,
            },
            {
              id: "workflows",
              title: "Workflow",
              type: "collapse",
              children: [
                {
                  id: "workflows",
                  title: "All Workflow",
                  type: "item",
                  url: "/workflows",
                  breadcrumbs: true,
                },
                {
                  id: "workflowStates",
                  title: "Workflow States",
                  type: "item",
                  url: "/workflow-states/",
                  breadcrumbs: true,
                },
                {
                  id: "workflowActions",
                  title: "Workflow Actions",
                  type: "item",
                  url: "/workflow-actions",
                  breadcrumbs: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default menuItems;
