import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  clearDataFromLocalStorage,
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "utils/localStore.utils";

const IdleUserMonitoring = (props: any) => {
  const [_timer, setTimer] = useState(moment());
  let timer = 900000 as any;

  const events = ["click", "scroll", "load", "keydown", "mousemove"];

  const event_handler = (eventType: Event) => {
    saveDataToLocalStorage("lastInteractionTime", moment());
    setTimer(_timer);
    if (timer) {
      start_timer();
    }
  };

  const start_timer = () => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(
      () => {
        let lastInteractionTime = getDataFromLocalStorage(
          "lastInteractionTime"
        );

        const user = getDataFromLocalStorage("user");

        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );

        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : 900000;

        if (diff.milliseconds < timeOutInterval) {
          start_timer();
        } else if (
          user?.user_id === "hitech_developers" ||
          user?.user_id === "bahram_pourjam"
        ) {
        } else {
          clearDataFromLocalStorage("token");
          clearDataFromLocalStorage("user");
          clearDataFromLocalStorage("lastInteractionTime");
          window.location.reload();
        }
      },

      props.timeOutInterval ? props.timeOutInterval : 900000
    );
  };

  const add_events = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, event_handler);
    });
  };

  useEffect(() => {
    add_events();
    // eslint-disable-next-line
  }, [JSON.stringify(_timer)]);

  return <div></div>;
};

export default IdleUserMonitoring;
