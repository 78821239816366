import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";

export interface DepartmentState {
  all_departments_data: any;
  all_departments: any;
  single_department: any;
  create_department: any;
  update_department: any;

  all_department_paginate: any;

  activity: boolean;
}

interface APIParams {
  end_point: string;
  body: any;
}

const initialState: DepartmentState = {
  all_departments_data: [],
  all_departments: [],
  single_department: {},
  create_department: {},
  update_department: {},

  all_department_paginate: [],

  activity: true,
};

export const all_departments = createAsyncThunk(
  "/departments/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "departments"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_department = createAsyncThunk(
  "/departments/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "departments"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_department = createAsyncThunk(
  "/department/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "department"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_department = createAsyncThunk(
  "/department/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "department"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const departments_slicer = createSlice({
  name: "deparments",
  initialState,
  reducers: {
    handle_clear_form: (state: DepartmentState, action: any) => {
      state.create_department = {};
      state.update_department = {};
      state.single_department = {};
      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(all_departments.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_departments.fulfilled, (state, { payload }) => {
        try {
          let departments = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            departments.push([
              { name: item?._id || "Not Available", hidden: true },
              {
                name: item?.department_code || "Not Available",
                alignment: "left",
              },
              {
                name: item?.department_name || "Not Available",
                alignment: "left",
              },
              {
                name: item?.description || "Not Available",
                alignment: "left",
              },
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#D2112B",
                alignment: "center",
              },
              {
                name: moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name: item?.added_by || "Admin",
                alignment: "left",
              },
              {
                name: item?.modified_by
                  ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                alignment: "center",
              },
              {
                name: item?.modified_by || "Not Available",
                alignment: "left",
              },
            ]);
          }

          state.all_department_paginate = payload;
          state.all_departments_data = payload?.data;
          state.all_departments = departments;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(single_department.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_department.fulfilled, (state, { payload }) => {
        try {
          state.single_department = { ...payload.data, call_time: new Date() };
          state.activity = false;
        } catch (error) {}
      })

      .addCase(create_department.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_department.fulfilled, (state, { payload }) => {
        try {
          state.create_department = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_department.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_department.fulfilled, (state, { payload }) => {
        try {
          state.update_department = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      });
  },
});

export const { handle_clear_form } = departments_slicer.actions;

export default departments_slicer.reducer;
