import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";

export interface DesignationState {
  all_designations_data: any;
  all_designations: any;
  single_designation: any;
  create_designation: any;
  update_designation: any;

  all_designations_paginate: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
  body: {};
}

const initialState: DesignationState = {
  all_designations_data: [],
  all_designations: [],
  single_designation: {},
  create_designation: {},
  update_designation: {},

  all_designations_paginate: [],
  activity: true,
};

export const all_designations = createAsyncThunk(
  "/designations/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "designations"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_designation = createAsyncThunk(
  "/designations/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "designations"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_designation = createAsyncThunk(
  "/designation/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "designation"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_designation = createAsyncThunk(
  "/designation/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "designation"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const designations_slicer = createSlice({
  name: "designations",
  initialState,
  reducers: {
    handle_clear_form: (state: DesignationState, action: any) => {
      state.single_designation = {};
      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(all_designations.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_designations.fulfilled, (state, { payload }) => {
        try {
          let designations = [] as any;

          // payload?.data.sort(function (a: any, b: any) {
          //   var keyA = a?.designation_code ?? 1,
          //     keyB = b?.designation_code ?? 1;
          //   if (keyA < keyB) return -1;
          //   if (keyA > keyB) return 1;
          //   return 0;
          // });

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            designations.push([
              { name: item?._id || "Not Available", hidden: true },
              {
                name: item?.designation_code || "Not Available",
                alignment: "left",
              },
              {
                name: item?.designation_name || "Not Available",
                alignment: "left",
              },
              {
                name: item?.department_name || "Not Available",
                alignment: "left",
              },
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#D2112B",
                alignment: "center",
              },
              {
                name: item?.description || "Not Available",
                alignment: "left",
              },
              {
                name: moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name: item?.added_by || "Hitech Developers",
                alignment: "left",
              },
              {
                name: item?.modified_by
                  ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                alignment: "center",
              },
              {
                name: item?.modified_by || "Not Available",
                alignment: "left",
              },
            ]);
          }

          state.all_designations_paginate = payload;
          state.all_designations_data = payload?.data;
          state.all_designations = designations;
          state.create_designation = {};
          state.update_designation = {};
          state.activity = false;
        } catch (error) {}
      })

      .addCase(single_designation.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_designation.fulfilled, (state, { payload }) => {
        try {
          state.single_designation = { ...payload.data, call_time: new Date() };
          state.activity = false;
        } catch (error) {}
      })

      .addCase(create_designation.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_designation.fulfilled, (state, { payload }) => {
        try {
          state.create_designation = { ...payload, call_date: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_designation.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_designation.fulfilled, (state, { payload }) => {
        try {
          state.update_designation = { ...payload, call_date: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      });
  },
});

export const { handle_clear_form } = designations_slicer.actions;

export default designations_slicer.reducer;
