import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

/* MATERIAL UI */
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";

/* MATERIAL ICONS*/
import {
  AssistantDirectionOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";

/* PROJECT IMPORTS */
import NavItem from "../NavItem";
import { getDataFromLocalStorage } from "utils/localStore.utils";
import { FinanceSVG, HumanResourceSVG, InventorySVG, ReportingAnalyticSVG, SaleSVG, SettingSVG } from "assets/svgs";

const NavCollapse = ({ menu, level }: any) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  const checkOpenForParent = (child: any, id: any) => {
    child.forEach((item: any) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  useEffect(() => {
    setOpen(false);
    setSelected(null);
    if (menu.children?.length > 0) {
      menu.children.forEach((item: any) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        const currentIndex = pathname
          .toString()
          .split("/")
          .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children]);

  const can_perform_action = (item: any) => {
    try {
      let user = getDataFromLocalStorage("user");

      let indexz = user?.permissions?.findIndex((itemx: any) => {
        return Number(itemx?.module_id) === Number(item?.module_id)
      });

      if ((item?.type === "item" && user?.permissions?.[indexz]?.read) ||
        (item?.type === "item" && user?.permissions?.[indexz]?.write) ||
        (item?.type === "item" && user?.permissions?.[indexz]?.update) ||
        (item?.type === "item" && user?.permissions?.[indexz]?.delete) ||
        (item?.type === "item" && user?.permissions?.[indexz]?.print) ||
        (item?.type === "item" && user?.permissions?.[indexz]?.download)) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* MENU COLLAPSE & ITEMS */

  // eslint-disable-next-line
  const menus = menu.children?.map((item: any) => {
    if (can_perform_action(item)) {
      switch (item.type) {
        case "collapse":
          return <NavCollapse key={item.id} menu={item} level={level + 1} />;
        case "item":
          return <NavItem key={item.id} item={item} level={level + 1} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    }
  });

  const getDrawerIcons = (type: any) => {
    if (type === "sales") {
      return <SaleSVG width={20} height={20} />;
    } else if (type === "inventory") {
      return <InventorySVG width={20} height={20} />;
    } else if (type === "finance") {
      return <FinanceSVG width={20} height={20} />;
    } else if (type === "human-resources") {
      return <HumanResourceSVG width={20} height={20} />;
    } else if (type === "reporting-analytics") {
      return <ReportingAnalyticSVG width={20} height={20} />;
    } else if (type === "settings") {
      return <SettingSVG width={20} height={20} />;
    } else {
      return <AssistantDirectionOutlined style={{ marginRight: 10 }} />;
    }
  };

  return (
    <>
      <ListItemButton
        sx={{
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: `${level * 5}px`,
          pl: 1,
          textTransform: "uppercase",
          borderRadius: 1,
        }}
        onClick={handleClick}
        disabled={menu.disabled}
      >
        <ListItemIcon sx={{ my: "auto", minWidth: !menu?.icon ? 18 : 36 }}>
          {getDrawerIcons(menu.id)}
        </ListItemIcon>

        <p style={{ marginBottom: 0, flex: 1 }}>{menu.title}</p>

        {open ? (
          <ExpandLess style={{ marginTop: "auto", marginBottom: "auto" }} />
        ) : (
          <ExpandMore style={{ marginTop: "auto", marginBottom: "auto" }} />
        )}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
