import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import AppRoute from "routes";
import NavigationScroll from "layout/NavigationScroll";
import themes from "themes";
import { ToastContainer } from "react-toastify";
import IdleUserMonitoring from "views/idle_user_monitoring";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  const customization = useSelector((state: any) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <IdleUserMonitoring timeOutInterval={900000} />
        <ToastContainer
          position="bottom-left"
          autoClose={2000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="colored"
        />
        <NavigationScroll>
          <CssBaseline />
          <AppRoute />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
