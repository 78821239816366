import { useRoutes } from "react-router-dom";
import { MainAppRoutes } from "./MainAppRoutes";
import { AuthenticationRoutes } from "./AuthenticationRoutes";
import useStyles from "./style-jss";
import { getStringFromLocalStorage } from "utils/localStore.utils";

export function AppRoute() {
  const classes = useStyles() as any;
  let element = MainAppRoutes({ classes });
  let element_2 = AuthenticationRoutes({ classes });

  let token = getStringFromLocalStorage("token");

  // eslint-disable-next-line
  if (!token) return useRoutes([element_2]);
  
  // eslint-disable-next-line
  return useRoutes([element]);
}

export default AppRoute;
