import { Typography } from "@mui/material";
import NavGroup from "../NavGroup";
import menuItem from "menu-items";
import React, { useEffect } from "react";
import { getDataFromLocalStorage } from "utils/localStore.utils";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { all_modules } from "store/modulesReducer";
import { useDispatch } from "react-redux";
import { capitalize_string } from "utils/helper";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      menuItems: menuItem?.items
    }
  );

  const modules_slicer = useSelector<any>((state: RootState) => state.modules) as any;
  const dispatch = useDispatch<any>();

  const get_all_modules = () => {
    try {
      dispatch(
        all_modules({
          end_point: "/modules",
        })
      );
    } catch (error) { }
  };

  useEffect(() => {
    get_all_modules();
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    let modules = [] as any;
    let user = getDataFromLocalStorage("user") as any;

    for (let index = 0; index < modules_slicer?.all_modules_data?.length; index++) {
      const element = modules_slicer?.all_modules_data[index];

      const indz = modules.findIndex((item: any) => Number(item.module_id) === Number(element?.parent_module_id));

      if (element?.parent_module_id === null) {
        modules.push({
          module_id: Number(element?._id),
          id: "-" + (element?.module_name?.toLowerCase()).replace(/ /g, "-"),
          title: capitalize_string(element?.module_name),
          type: 'group',
          children: [
            {
              module_id: Number(element?._id),
              id: (element?.module_name?.toLowerCase()).replace(/ /g, "-"),
              title: capitalize_string(element?.module_name),
              type: "item",
              url: `/${(element?.module_name?.toLowerCase()).replace(/ /g, "-")}`,
              breadcrumbs: true,
            }
          ]
        });
      }

      if (indz !== -1) {
        modules.push({
          module_id: Number(modules?.[indz]?.module_id),
          id: (modules?.[indz]?.id?.toLowerCase()).replace(/ /g, "-"),
          title: capitalize_string(modules?.[indz]?.title),
          type: 'group',
          children: [
            {
              module_id: Number(modules?.[indz]?.module_id),
              id: (modules?.[indz]?.children?.[0]?.id?.toLowerCase()).replace(/ /g, "-"),
              title: capitalize_string(modules?.[indz]?.children?.[0]?.title),
              type: "collapse",
              children: [
                ...modules?.[indz]?.children?.[0]?.children || [],
                {
                  module_id: Number(element?._id),
                  id: (element?.module_name?.toLowerCase()).replace(/ /g, "-"),
                  title: capitalize_string(element?.module_name),
                  type: "item",
                  url: `/${(modules?.[indz]?.children?.[0]?.id?.toLowerCase()).replace("-", "-")}/${(element?.module_name?.toLowerCase()).replace(/ /g, "-")}`,
                  breadcrumbs: true,
                }]
            }
          ]
        });

        modules.splice(indz, 1);
      }
    }

    let modules_array = ["dashboard", "sales", "purchase", "inventory", "finance", "human resources", "reporting analytics", "settings"];
    let sorted_modules = [] as any;
    let update_module = [];

    for (let index = 0; index < modules?.length; index++) {
      const _item = modules[index];
      let visibility = true;

      for (const item of _item?.children) {
        if (item?.type === "collapse") {
          let visibility_check = user?.permissions?.filter((j: any) =>
            item?.children?.some((i: any) => j.module_id === i?.module_id && (j.read || j.write || j.update || j.delete || j.print || j.download))
          );

          if (visibility_check?.length > 0) {
            visibility = true;
          } else {
            visibility = false;
          }
        } else {
          let visibility_check = user?.permissions?.filter((j: any) => j.module_id === item?.module_id
            && (j.read || j.write || j.update || j.delete || j.print || j.download)
          );

          if (visibility_check?.length > 0) {
            visibility = true;
          } else {
            visibility = false;
          }
        }
      }

      update_module.push({
        ..._item,
        visibility
      })
    }

    for (let index = 0; index < modules_array.length; index++) {
      const element = modules_array[index];

      // eslint-disable-next-line
      update_module?.map((item: any) => {
        let fa = item.title.toLowerCase();

        modules_array.includes(fa)

        if (element === fa) {
          sorted_modules.push(item);
        }
      });
    }

    let filtering_modules = update_module?.filter((item: any) => {
      return !sorted_modules?.some((itemx: any) => {
        return item?.title?.toLowerCase() === itemx?.title?.toLowerCase()
      })
    });

    sorted_modules = [...sorted_modules, ...filtering_modules];

    setState({
      modules_list: sorted_modules,
    });
    // eslint-disable-next-line
  }, [JSON.stringify(modules_slicer?.all_modules)]);

  // eslint-disable-next-line
  const navItems = state.modules_list?.map((item: any) => {
    if (item?.visibility) {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
